const statementPayerStatuses = {
    pendingApproval     : 'Pending Approval',
    rejected            : 'Rejected',
    pendingCollection   : 'Pending Collection',
    processingCollection: 'Processing Collection',
    collectionProcessed : 'Collection Processed',
    collectionFailed    : 'Collection Failed'
}

const statementPayeeStatuses = {
    pendingApproval  : 'Pending Approval',
    rejected         : 'Rejected',
    pendingPayment   : 'Pending Payment',
    processingPayment: 'Processing Payment',
    paymentProcessed : 'Payment Processed',
    paymentFailed    : 'Payment Failed'
}

export {statementPayerStatuses, statementPayeeStatuses};
