import React, {useRef, useState, Fragment, useEffect} from 'react';
import {useReactToPrint} from 'react-to-print';
import {utils, writeFileXLSX} from 'xlsx';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

import {CardWithHeaderFooterActions} from '../../../components/layout/CardContent';
import Loading from '../../../components/Loading';
import {EmptyData} from '../../../components/EmptyData';
import {GroupedTable} from '../../../components/Tables';
import {ErrorAlert, NotificationAlert} from '../../../components/GeneralError';

import {downloadCSVFile, downloadPDFFile} from '../../../utils/JsUtils';
import {GroupBy} from '../../../utils/GroupByHelper';

import {HttpContext} from '../../../dataAccess/HttpService';
import {ConfirmModal} from "../../../components/modals/Modals";

import {Settings} from '../../../utils/Settings';
import {statementPayeeStatuses, statementPayerStatuses} from "../../../enums/statementStatuses";

const cleanSheetName = (sheetName) => {
	return sheetName.replace(/[/\\?%*:|"<>]/g, '-');
};

const calculateTotal = (array, propName) => {
	let total = 0;
	const props = propName.split('.');
	array.forEach((item) => {
		total += props.reduce((obj, prop) => (obj && obj[prop] !== undefined ? obj[prop] : 0), item);
	});
	return total;
};

const ReimbursementActions = (translate, index = null) => {
	const actions = [
		{ label: translate('r.approve'), value: statementPayeeStatuses.pendingPayment },
		{ label: translate('r.reject'), value: statementPayeeStatuses.rejected },
	];

	if (index != null) return actions[index];
	return actions;
};

const HeaderActions = ({ action }) => {
	const { hasData, printForm, downloadFile, onRefresh, isLoading } = action;
	const [showDropdown, setShowDropdown] = useState(false);

	const { t } = useTranslation();

	const toggleDropDown = () => {
		setShowDropdown(!showDropdown);
	};

	const triggerDownload = (e, format) => {
		e.preventDefault();
		toggleDropDown();
		downloadFile(format);
	};

	return (
		<Fragment>
            <div className="d-none d-sm-flex align-items-center d-print-none">
                <button className="btn btn-sm btn-primary mr-2" disabled={isLoading} onClick={onRefresh}>
                    <i className="fal fa-refresh mr-1"/>
                    {t('g.refresh')}
                </button>

                <button className="btn btn-sm btn-primary mr-2" disabled={!hasData} onClick={printForm}>
                    <i className="fal fa-print mr-1"/>
                    {t('g.print')}
                </button>

                <button className="btn btn-sm btn-secondary mr-2" disabled={!hasData}>
                    <i className="fal fa-envelope mr-1"/>
                    {t('g.email')}
                </button>

                <div className="dropdown">
                    <button className="btn btn-sm btn-outline-secondary mr-2 dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
                        <i className="fal fa-download mr-2"/>
                        {t('g.download')}
                    </button>
                    <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
                            .xlsx
                        </a>
                        <a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
                            .csv
                        </a>
                    </div>
                </div>
            </div>

            <div className="d-flex d-sm-none">
                <button className="btn btn-icon btn-primary mr-2" disabled={!hasData} onClick={printForm}>
                    <i className="fal fa-print" />
				</button>
				<button className="btn btn-icon btn-secondary mr-2" disabled={!hasData}>
					<i className="fal fa-envelope" />
				</button>
				<div className="dropdown">
					<button className="btn btn-icon btn-outline-secondary dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
						<i className="fal fa-download" />
					</button>
					<div className={`dropdown-menu ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
							.xlsx
						</a>
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
							.csv
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const buildHeaders = (t, selectAll = false) => {
	let headers = [];
	headers.push({ title: t('g.view'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-lg-left', style: { width: '2%' } });

	headers.push({ title: t('r.statementRecipient'), className: 'align-top p-0 pr-1 col-2 col-lg-1', style: { width: '15%' } });
	headers.push({ title: t('r.payerPayee'), className: 'align-top p-0 pr-1 col-2 col-lg-1', style: { width: '10%' } });
	headers.push({ title: t('r.paymentPeriod'), className: 'text-left align-top p-0 pr-1 col-2 col-lg-1', style: { width: 'auto' } });
	headers.push({ title: t('r.approver'), className: 'text-left align-top p-0 pr-1 col-2 col-lg-1', style: { width: '15%' } });
	headers.push({ title: t('r.status'), className: 'text-left align-top p-0 pr-1 col-2 col-lg-1', style: { width: '10%' } });
	headers.push({ title: t('r.totalDuration'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-sm-center text-lg-right', style: { width: 'auto' } });
	headers.push({ title: t('g.totalEnergy'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-sm-center text-lg-right', style: { width: 'auto' } });
	headers.push({ title: t('r.value'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-sm-center text-lg-right', style: { width: 'auto' } });
	headers.push({ title: t('r.actions'), className: 'col-1 d-print-none text-right align-top p-0 pr-1', style: { width: '4%' } });

	{
		selectAll && headers.push({ title: t('r.select'), className: 'col-1 d-print-none text-right align-top p-0 pr-1', style: { width: '3%' } });
	}

	return headers;
};

const TableRow = (item, index, rest, updateFilter, downloadPdf, isBusyDownloading, selectAll = false, toggleCheckbox = null) => {
	const { t } = rest;
	const [isDownloading, setIsDownloading] = useState(false);

	const downloadingPdf = (index, item) => {
		setIsDownloading(true);
		downloadPdf(index, item);
	};

	useEffect(() => {
		if (!isBusyDownloading) {
			setIsDownloading(false);
		}
	}, [isBusyDownloading]);

	const showStatusInfoIcon = (item) => {
		if (!item) return false;

		if (item.recipientType?.toLowerCase() === 'payer') {
			return item.payerStatus.toLowerCase() === statementPayerStatuses.pendingApproval.toLowerCase() ||
			       item.payerStatus.toLowerCase() === statementPayerStatuses.processingCollection.toLowerCase() ||
				   item.payeeStatus.toLowerCase() === statementPayerStatuses.collectionFailed.toLowerCase() ||
				   item.payerStatus.toLowerCase() === statementPayerStatuses.rejected.toLowerCase();
		} else {
			return item.payeeStatus.toLowerCase() === statementPayeeStatuses.pendingApproval.toLowerCase() ||
				   item.payeeStatus.toLowerCase() === statementPayeeStatuses.processingPayment.toLowerCase() ||
				   item.payeeStatus.toLowerCase() === statementPayeeStatuses.paymentFailed.toLowerCase() ||
				   item.payeeStatus.toLowerCase() === statementPayeeStatuses.rejected.toLowerCase();
		}
	}

	const setStatusTooltip = (item) => {
		if (!item) return '';

		if (item.recipientType?.toLowerCase() === 'payer') {
			switch (item.payerStatus.toLowerCase()) {
				case statementPayerStatuses.pendingApproval.toLowerCase():
					return t('r.statementsPendingApproval');
				case statementPayerStatuses.processingCollection.toLowerCase():
					return t('r.statementsProcessingCollection');
				case statementPayerStatuses.collectionFailed.toLowerCase():
					return t('r.statementsCollectionFailed');
				case statementPayerStatuses.rejected.toLowerCase():
					return t('r.statementsRejected');
				default: return item.payerStatus;
			}
		} else {
			switch (item.payeeStatus.toLowerCase()) {
				case statementPayeeStatuses.pendingApproval.toLowerCase():
					return t('r.statementsPendingApproval');
				case statementPayeeStatuses.processingPayment.toLowerCase():
					return t('r.statementsProcessingPayment');
				case statementPayeeStatuses.paymentFailed.toLowerCase():
					return t('r.statementsPaymentFailed');
				case statementPayeeStatuses.rejected.toLowerCase():
					return t('r.statementsRejected');
				default: return item.payeeStatus;
			}
		}
	}

	return (
		<Fragment>
			<tr key={`table-row-1-${index}`} className="d-none d-md-table-row" onClick={(event) => { toggleCheckbox?.(event, item) }}>
				<td className="p-0 pb-2 pt-2" style={{ width: '2%', whiteSpace: 'nowrap' }}>
					<Link className="d-print-none" to={'#'} onClick={(e) => updateFilter(e, index, item)}>
						<i className="fas fa-magnifying-glass" />
					</Link>
					<div className="d-none d-print-block">
						<i className="fas fa-magnifying-glass" />
					</div>
				</td>

				<td className="text-left p-0 pb-2 pt-2" style={{ width: '15%', paddingLeft: '0.25rem' }}>
					<Link className="d-print-none" to={'#'} onClick={(e) => updateFilter(e, index, item)}>
						{item.recipientType?.toLowerCase() === 'payer' ? item.payerName : item.payeeName}
					</Link>
					<div className="d-none d-print-block">{item.recipientType?.toLowerCase() === 'payer' ? item.payerName : item.payeeName}</div>
				</td>
				<td className="text-left p-0 pb-2 pt-2 pr-1" style={{ width: '10%' }}>{item.recipientType}</td>
				<td className="text-left p-0 pb-2 pt-2 pr-1" style={{ width: 'auto' }}>{item.statementPeriod}</td>
				<td className="text-left p-0 pb-2 pt-2 pr-1" style={{ width: '15%' }}>{item.approvedUserDisplayName}</td>

				<td className="text-left p-0 pb-2 pt-2 pr-1" style={{width: '10%'}}>
					{showStatusInfoIcon(item) && <i className="fa fa-info-circle pr-1 text-primary" aria-hidden="true" title={setStatusTooltip(item)} />}
					{item.recipientType?.toLowerCase() === 'payer' ? item.payerStatus : item.payeeStatus}
				</td>

				<td className="text-right p-0 pb-2 pt-2 pr-1" style={{width: 'auto'}}>{Settings.formatDuration(item.totalDurationSec * 1000, null, 'HH:mm:ss')}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1" style={{ width: 'auto' }}>{Settings.formatNumber(item.totalEnergyUsedKwh, 2)}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1" style={{ width: 'auto' }}>
					{Settings.formatNumber(item.statementAmounts.amountIncl, 2)} {item.currency}
				</td>
				<td className="text-right p-1 pr-2" style={{ width: '4%', whiteSpace: 'nowrap' }}>
					<button className="btn btn-sm btn-outline-primary" onClick={() => downloadingPdf(index, item)} title={`PDF ${t('g.download')}`}>
						<i className={`fal ${isDownloading ? 'fa-refresh fa-spin' : 'fa-download'}`} />
					</button>
				</td>
				{selectAll && toggleCheckbox !== null && (
					<td className="text-right p-0 pt-2 pr-3" style={{ width: '3%' }}>
						<input type="checkbox" checked={item.checked} readOnly={true} disabled={!item.canUserApproveStatement} />
					</td>
				)}
			</tr>

			<tr key={`table-row-2-${index}`} className="d-table-row d-md-none d-print-none">
				<td className="border">
					<div className="d-flex align-items-center">
						<div className="d-flex flex-column flex-grow-1">
							<div className="mb-1">
								<div className="flex-grow-1">
									<i className="fal fa-user ml-1 mr-2" />
									{item.recipientType.toLowerCase() === 'payer' ? item.payerName : item.payeeName}
								</div>
							</div>

							<div className="mb-1">
								<div className="flex-grow-1">
									<i className="fal fa-clock ml-1 mr-2" />
									{Settings.formatDuration(item.totalDurationSec * 1000, null, 'HH:mm:ss')} (h:m:s)
								</div>
							</div>

							<div className="mb-1">
								<div className="flex-grow-1">
									<i className="fal fa-spinner ml-1 mr-2" />
									{item.recipientType?.toLowerCase() === 'payer' ? item.payerStatus : item.payeeStatus}
								</div>
							</div>

							<div className="d-flex">
								<div className="flex-grow-1">
									<i className="fal fa-battery-bolt ml-1 mr-2" />
									{Settings.formatNumber(item.totalEnergyUsedKwh, 2)} {t('g.kwh')}
								</div>
								<div className="flex-grow-1 text-right">
									<i className="fal fa-circle-dollar ml-1 mr-2" />
									{Settings.formatNumber(item.statementAmounts.amountIncl, 2)} {item.currency}
								</div>
							</div>

							<div className="d-flex mt-3 mb-2">
								<button className="btn btn-sm btn-outline-primary" onClick={() => downloadingPdf(index)} title={`${t('r.statement')} PDF ${t('g.download')}`}>
									<i className={`fal ${isDownloading ? 'fa-refresh fa-spin' : 'fa-download'}`} />
									<span className="ml-2">{t('r.statement')} PDF {t('g.download')}</span>
								</button>
							</div>
						</div>
						{selectAll && toggleCheckbox !== null && (
							<div className="text-center p-0 mt-2" style={{ width: '2%' }}>
								<input type="checkbox" className="mr-2" checked={item.checked} readOnly={true} />
							</div>
						)}
						<div className="ml-3">
							<Link className="d-print-none" to={`#`} onClick={(e) => updateFilter(e, index, item)}>
								<i className="fal fa-chevron-right font-weight-bold" />
							</Link>
						</div>
					</div>
				</td>
			</tr>
		</Fragment>
	);
};

const tableFooter = (items = null, rest, selectAll = false) => {
	if (items === null) return null;

	const { t } = rest;

	const totalDuration = Settings.formatDuration(calculateTotal(items, 'totalDurationSec') * 1000, null, 'HH:mm:ss');
	const totalEnergy = Settings.formatNumber(calculateTotal(items, 'totalEnergyUsedKwh'), 2);
	const totalBilled = Settings.formatNumber(calculateTotal(items, 'statementAmounts.amountIncl'), 2);

	return (
		<>
			<tr className="d-md-table-row">
				<td></td>
			</tr>
			<tr className="text-secondary d-none d-md-table-row">
				<td className="p-0 pb-2 pt-2 pr-1"></td>

				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>

				<td className="text-left p-0 pb-2 pt-2 pr-1">{t('t.reportTotal')}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalDuration}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalEnergy}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalBilled}</td>
				{selectAll && <td className="p-0 pb-2 pt-2 pr-1"></td>}
			</tr>

			<tr className="text-secondary d-table-row d-md-none d-print-none">
				<div className="d-flex flex-grow-1 pl-3 pr-3">
					<div className="flex-grow-1 text-right">
						<div>{t('r.totalDuration')}</div>
						<div>
							<i className="fal fa-clock ml-1 mr-2" />
							{totalDuration}
						</div>
					</div>
					<div className="flex-grow-1 text-right">
						<div>{t('g.totalEnergy')}</div>
						<div>
							<i className="fal fa-battery-bolt ml-1 mr-2" />
							{totalEnergy}
						</div>
					</div>
					<div className="flex-grow-1 text-right">
						<div>
							{t('t.reportTotal')} {t('r.value')}
						</div>
						<div>
							<i className="fal fa-circle-dollar ml-1 mr-2" /> {totalBilled}
						</div>
					</div>
				</div>
			</tr>
		</>
	);
};

const FooterActions = ({ action, hasData, calculateTotal = null, translate }) => {
	const onActionClicked = (e, format) => {
		action(e, format);
	};

	return (
		<div className="d-none d-sm-flex justify-content-end align-items-center d-print-none ">
			{calculateTotal && (
				<div className="ml-2 mr-3">
					{translate('r.total')}: {Settings.formatNumber(calculateTotal(), 2)}
				</div>
			)}
			<button className="btn btn-sm btn-success ml-2" disabled={!hasData} onClick={(e) => onActionClicked(e, ReimbursementActions(translate, 0).value)}>
				<i className="fal fa-check mr-1" />
				{ReimbursementActions(translate, 0).label}
			</button>
			<button className="btn btn-sm btn-danger ml-2" disabled={!hasData} onClick={(e) => onActionClicked(e, ReimbursementActions(translate, 1).value)}>
				<i className="fal fa-times mr-1" />
				{ReimbursementActions(translate, 1).label}
			</button>
		</div>
	);
};

// Add this function to group items by payerId
const groupByProp = (arr, property) => {
	const sortedData = _.orderBy(arr, [property]);

	return sortedData.reduce((acc, obj) => {
		const key = obj[property] || '';
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(obj);
		return acc;
	}, {});
};

const groupTemplate = (item = null, rest, t, selectAll = false) => {
	if (item === null) return null;

	const { title = t('t.total') } = rest;

	const totalDuration = Settings.formatDuration(calculateTotal(item.data, 'totalDurationSec') * 1000, null, 'HH:mm:ss');
	const totalEnergy = Settings.formatNumber(calculateTotal(item.data, 'totalEnergyUsedKwh'), 2);
	const totalBilled = Settings.formatNumber(calculateTotal(item.data, 'statementAmounts.amountIncl'), 2);

	return (
		<Fragment>
			<tr className="text-primary d-none d-md-table-row">
				<td className="d-print-none p-0 pb-2 pt-2 pr-1"></td>

				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>

				<td className="text-left p-0 pb-2 pt-2 pr-1">{title}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalDuration}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalEnergy}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalBilled}</td>
				{selectAll && <td className="p-0 pb-2 pt-2 pr-1"></td>}
			</tr>

			{/*------ Mobile View ------*/}
			<tr className="text-primary d-table-row d-md-none">
				<div className="d-flex pt-2">
					<div className="flex-grow-1">{title}</div>
					<div className="flex-grow-1 text-right">{`${totalEnergy} kWh`}</div>
					{/* <div className="ml-2">{totalDuration}</div> */}
				</div>
			</tr>
		</Fragment>
	);
};

export const ReimbursementTable = (props) => {

	const { t } = useTranslation();
	const { statementPdfDownload, statementReimburse } = HttpContext();

	let { title = t('r.report'), items, isLoading = true, loadMore = null, setFilter, filter, selectAll = true, onRefresh = null, } = props;
	const confirmationModalDefault = { isOpen: false, title: null, message: null, btn1Text: null, btn2Text: null, btn2Action: null, isBusy: false };

	const [isBusy, setIsBusy] = useState(false);
	const [hasSuccess, setHasSuccess] = useState('');
	const [hasError, setHasError] = useState('');
	const [isBusyDownloading, setIsBusyDownloading] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState({ ...confirmationModalDefault });

	const printRef = useRef();
	const downRef = useRef();
	const selectedRecords = useRef([]);

	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		if (Array.isArray(items) || items.length > 0) {
			const newItems = items.map((item) => {
				item.checked = false;
				return item;
			});

			setSelectedItems(newItems);
		}
	}, [items]);

	useEffect(() => {
		if (hasError) window.scrollTo(0, 0);
	}, [hasError]);

	const isStatementIssuerUser = items.some((item) => item.isStatementIssuerUser);
	if (!isStatementIssuerUser) {
		selectAll = false; // Turn off the checkbox, select / deselect all functionalities
	}

	const headers = buildHeaders(t, selectAll);

	const updateFilter = (e, dataIndex, dataItem) => {
		e.preventDefault();

		setFilter({
			...filter.slice(-1)[0],
			dataIndex,
			dataItem,
			description: t('t.chargeTransactions'),
			dataType: 'summaryTable',
		});
	};

	const printForm = useReactToPrint({ content: () => printRef.current });

	const downloadPdf = async (index, dataItem) => {
		setHasError('');

		if (!dataItem) {
			setHasError('Error downloading statement PDF file.');
			return;
		}

		setIsBusyDownloading(true);

		const result = await statementPdfDownload(dataItem.recId);

		const customerName = dataItem.recipientType?.toLowerCase() === 'payer' ? dataItem.payerName : dataItem.payeeName;
		const statementPeriod = dataItem.statementPeriod;
		const defaultFilename = `statement_${customerName}_${statementPeriod}`;

		if (!result?.data) {
			setHasError('Error downloading statement PDF file.');
			setIsBusyDownloading(false);
			return;
		}

		const filename = !result.headers['content-disposition'] ? defaultFilename : result.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/\"/g, '');

		setIsBusyDownloading(false);
		downloadPDFFile(result.data, filename);
	};

	const downloadFile = async (format = 'xlsx') => {
		const workbook = utils.book_new();
		let allData = [{ sheetName: t('t.allTranactions'), sheetData: [] }];

		GroupBy(items, 'chargeTokenId').forEach((element) => {
			const data = element.data.map((row) => {
				return {
					Payee: row.payeeName,
					PaymentPeriod: row.statementPeriod,
					Approver: row.approvedUserDisplayName,
					Status: row.status,
					TotalDuration: Settings.formatDuration(row.totalDurationSec * 1000, null, 'HH:mm:ss'),
					TotalkWh: row.totalEnergyUsedKwh,
					Invoiced: Settings.formatNumber(row.statementAmounts.reportingAmountExcl),
					Currency: row.statementAmounts.currency,
				};
			});

			const firstRow = element.data[0];
			const { chargePointPhysicalReference, chargePointLocation, chargePointDeviceId } = firstRow;

			allData.push({
				sheetName: `${chargePointPhysicalReference ?? chargePointLocation}-${chargePointDeviceId}`,
				sheetData: data,
			});

			allData[0].sheetData = [...allData[0].sheetData, ...data];
		});

		allData.forEach((row, index) => {
			const worksheet = utils.json_to_sheet(row.sheetData);
			utils.book_append_sheet(workbook, worksheet, cleanSheetName(row.sheetName));

			if (index === 0 && format === 'csv') downloadCSVFile(utils.sheet_to_csv(worksheet));
		});

		if (format === 'xlsx') writeFileXLSX(workbook, `${title}.xlsx`);
	};

	const toggleCheckbox = (event, item) => {
		if (!item.canUserApproveStatement) return;

		const isChecked = !item.checked;

		// Push/Splice transactionId onto selectedRecords variable.
		if (!isChecked) {
			const index = selectedRecords.current.findIndex((i) => i.statementId === item.recId);
			if (index >= 0) selectedRecords.current.splice(index, 1);
		} else {
			selectedRecords.current.push({
				statementId            : item.recId,
				canUserApproveStatement: item.canUserApproveStatement,
				recipientType          : item.recipientType,
				existingStatus		   : item.recipientType.toLowerCase() === 'payer' ? item.payerStatus : item.payeeStatus,
				payerStatus            : null,
				payeeStatus            : null
			});
		}

		// Check/uncheck the checkbox.
		const newItems = items.map((i) => {
			if (i.recId === item.recId) {
				i.checked = !i.checked;
			}
			return i;
		});

		setSelectedItems(newItems);
	};

	const toggleAllItems = (selectAll) => {
		const validStatements = items.filter((item) => item.canUserApproveStatement);

		// Check/uncheck all checkboxs.
		const newItems = validStatements.map((item) => {
			item.checked = selectAll;
			return item;
		});

		setSelectedItems(newItems);

		// Add all items onto selectedRecords variable.
		const selectedItems = validStatements.map((item) => {
			return {
				statementId            : item.recId,
				canUserApproveStatement: item.canUserApproveStatement,
				recipientType          : item.recipientType,
				existingStatus		   : item.recipientType.toLowerCase() === 'payer' ? item.payerStatus : item.payeeStatus,
				payerStatus            : null,
				payeeStatus            : null
			};
		});

		if (selectedItems.length <= 0) return
		selectedRecords.current = selectAll ? [...selectedItems] : [];
	};

	const calculateStatementTotal = () => {
		return items.reduce((accumulator, item) => {
			return item.checked ? accumulator + item.statementAmounts?.amountExcl || item.statementAmounts?.amountExcl : accumulator;
		}, 0);
	};

	const onStatementUpdate = async () => {
		setShowConfirmationModal({
			...showConfirmationModal,
			isOpen: false,
		});

		setIsBusy(true);

		try {
			let result = await statementReimburse(selectedRecords.current);
			if (result !== null && result.data !== false) {
				selectedRecords.current = [];
				setHasSuccess(t('r.statementsSubmittedForReimbursement'));
				if (onRefresh) onRefresh();
			} else {
				setHasError(`${t('r.errorReimbursingStatements')}${selectedRecords.current.length > 1 ? 's' : ''}. ${t('r.pleaseTryAgainLater')}`);
			}
		} catch (error) {
			console.error('error: ', error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	const updateStatements = (e, action) => {
		if (!selectedRecords.current || selectedRecords.current.length <= 0 || !action) {
			setHasError(t('r.noStatementsSelected'));
			return;
		}

		setHasError('');
		setHasSuccess('');

		// This is considered the approved status for Statements!
		const isApproved = action === statementPayeeStatuses.pendingPayment;

		selectedRecords.current.forEach((item) => {
			if (!item || !item.canUserApproveStatement) return;

			if (item.recipientType?.toLowerCase() === 'payer') {
				const willReject = item.existingStatus.toLowerCase() === statementPayerStatuses.pendingApproval.toLowerCase();

				return item.payerStatus = isApproved
					? statementPayerStatuses.pendingCollection
					: willReject ? statementPayerStatuses.rejected : statementPayerStatuses.collectionFailed;
			} else {
				const willReject = item.existingStatus.toLowerCase() === statementPayeeStatuses.pendingApproval.toLowerCase();

				item.payerStatus = isApproved
					? statementPayerStatuses.pendingCollection
					: willReject ? statementPayerStatuses.rejected : statementPayerStatuses.collectionFailed;

				item.payeeStatus = isApproved
					? statementPayeeStatuses.pendingPayment
					: willReject ? statementPayeeStatuses.rejected : statementPayeeStatuses.paymentFailed;
			}
		});

		const total = Settings.formatNumber(calculateStatementTotal(), 2);

		const message = t('r.statementReimbursementMessage', { count: selectedRecords.current.length, total: total });

		setShowConfirmationModal({
			isOpen: true,
			title: t('r.reimbursementConfirmation'),
			message: message,
			btn1Text: t('g.cancel'),
			btn2Text: t('r.proceed'),
			btn2Action: onStatementUpdate,
		});
	};

	const ShowConfirmation = () => {
		const close = () => {
			setShowConfirmationModal({ ...confirmationModalDefault });
		};

		return (
			<ConfirmModal
				isOpen={showConfirmationModal.isOpen}
				title={showConfirmationModal.title}
				btn1Text={showConfirmationModal.btn1Text}
				btn2Text={showConfirmationModal.btn2Text}
				btn1Action={close}
				btn2Action={showConfirmationModal.btn2Action}
				isBusy={isBusy}>
				{showConfirmationModal.message}
			</ConfirmModal>
		);
	};

	// Group items by payerId
	const groupedItems = groupByProp(items, 'payerName');
	let groupedItems2 = Object.keys(groupedItems).map((key) => {
		const data = groupedItems[key];

		// Sort data to ensure 'Payer' records are on top
		const sortedData = _.orderBy(data, [(item) => item.recipientType.toLowerCase() === 'payer' ? 0 : 1]);

		const groupedData = groupByProp(sortedData, groupedItems[1]);
		return { [key]: groupedData };
	});

	return (
		<div ref={printRef}>
			<style type="text/css" media="print">
				{'@page { size: landscape; }'}
			</style>

			<NotificationAlert message={hasSuccess} margin="" />
			<ErrorAlert message={hasError} margin="" />
			<ShowConfirmation isOpen={showConfirmationModal.isOpen} />

			<CardWithHeaderFooterActions
				heading={title}
				headerActions={<HeaderActions action={{ isLoading: isLoading, hasData: items.length > 0, printForm: printForm, downloadFile: downloadFile, downloadPdf: downloadPdf, onRefresh: onRefresh }} />}
				footer={t('r.reimbursements')}
				footerActions={<FooterActions action={updateStatements} hasData={Object.keys(groupedItems2).length > 0} calculateTotal={calculateStatementTotal} translate={t} />}
				hideFooter={isStatementIssuerUser}
			>
				{isLoading ? (
					<Loading message={`${t('g.loading')} ${title}...`} />
				) : Object.keys(groupedItems2).length <= 0 ? (
					<EmptyData />
				) : (
					<Fragment>
						<div ref={downRef}>
							<GroupedTable
								headers={headers}
								items={groupedItems2}
								rowTemplate={(item, index, rest) => TableRow(item, index, rest, updateFilter, downloadPdf, isBusyDownloading, selectAll, toggleCheckbox )}
								showGroupHeaders={true}
								// TODO: Re-introduce the below, when the grouping is sorted, and does not incorrectly calculate the values in the group / footer rows
								// groupTemplate={groupTemplate}
								// footTemplate={tableFooter}
								selectAll={selectAll && toggleAllItems}
								isManagePayments={true}
								t={t}
							/>
						</div>

						{loadMore && (
							<div className="d-flex justify-content-center">
								<button className="btn btn-link text-gray-500 text-xs" onClick={loadMore}>
									{t('g.loadMore')}
								</button>
							</div>
						)}
					</Fragment>
				)}
			</CardWithHeaderFooterActions>
		</div>
	);
};
